<template>
    <div>
        <!--zh-cn-->
        <div v-if="lang == 'zh_CN'">
            <section class="wrapper">
                <h1 id="tos">什么是助记词？</h1>
                <p>助记词是明文私钥的另一种表现形式，最早是由 BIP39 提案提出，其目的是为了帮助用户记忆复杂的私钥。助记词一般由12、15、18、21个单词构成，这些单词都取自一个固定词库，其生成顺序也是按照一定算法而来，所以用户没必要担心随便输入 12 个单词就会生成一个地址。任何人得到了你的助记词，可以不费吹灰之力的夺走你的资产。所以在用户在备份助记词之后，一定要注意三点:</p>
                <p><strong>在使用 Keystore 时有两点需要注意</strong></p>
                <ol>
                    <li><strong>尽可能采用物理介质备份，例如用笔抄在纸上等，</strong>尽可能不要采用截屏或者拍照之后放在联网的设备里，以防被黑客窃取；</li>
                    <li>多次验证备份的助记词是否正确，一旦抄错一两个单词，那么将对后续找回正确的助记词带来巨大的困难；</li>
                    <li>将备份后的助记词妥善保管，做好防盗防丢措施。</li>
                </ol>
                <p><strong>PS：用户可以使用备份的助记词，重新导入 GoodX Wallet，用这种方法来修改相应钱包的密码。</strong></p>
            </section>
        </div>

        <!--en-->
        <div v-else-if="lang == 'en'">
            <section class="wrapper">
                <h1 id="tos">What is a mnemonic phrase? </h1>
                <p>The mnemonic phrase is another form of expression of the plaintext private key. It was first proposed by the BIP39 proposal. Its purpose is to help users remember complex private keys. Mnemonic words are generally composed of 12, 15, 18, and 21 words. These words are all taken from a fixed vocabulary, and their generation sequence is also based on a certain algorithm, so users do not need to worry about entering 12 words randomly and generating one address. Anyone who gets your mnemonic can take away your assets effortlessly. So after the user backs up the mnemonic phrase, we must pay attention to three points:</p>
                <p><strong>There are two points to note when using Keystore</strong></p>
                <ol>
                    <li><strong>Use physical media for backup as much as possible, such as copying on paper with a pen, etc.</strong> Try not to take screenshots or take pictures and place them in networked devices to prevent hackers from stealing;</li >
                    <li>Verify that the backed up mnemonic words are correct for many times. Once one or two words are copied incorrectly, it will be difficult to retrieve the correct mnemonic words later;</li>
                    <li>Keep the backed up mnemonic words properly and take measures to prevent theft and loss. </li>
                </ol>
                <p><strong>PS: The user can use the backup mnemonic to re-import GoodX Wallet, and use this method to modify the password of the corresponding wallet. </strong></p>
            </section>
        </div>

        <!--zh_TW-->
        <div v-else>
            <section class="wrapper">
                <h1 id="tos">什麼是助記詞？ </h1>
                <p>助記詞是明文私鑰的另一種表現形式，最早是由 BIP39 提案提出，其目的是為了幫助用戶記憶複雜的私鑰。助記詞一般由12、15、18、21個單詞構成，這些單詞都取自一個固定詞庫，其生成順序也是按照一定算法而來，所以用戶沒必要擔心隨便輸入12 個單詞就會生成一個地址。任何人得到了你的助記詞，可以不費吹灰之力的奪走你的資產。所以在用戶在備份助記詞之後，一定要注意三點:</p>
                <p><strong>在使用 Keystore 時有兩點需要注意</strong></p>
                <ol>
                    <li><strong>盡可能採用物理介質備份，例如用筆抄在紙上等，</strong>盡可能不要採用截屏或者拍照之後放在聯網的設備裡，以防被黑客竊取；</li >
                    <li>多次驗證備份的助記詞是否正確，一旦抄錯一兩個單詞，那麼將對後續找回正確的助記詞帶來巨大的困難；</li>
                    <li>將備份後的助記詞妥善保管，做好防盜防丟措施。 </li>
                </ol>
                <p><strong>PS：用戶可以使用備份的助記詞，重新導入 GoodX Wallet，用這種方法來修改相應錢包的密碼。 </strong></p>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Mnemonic',
        components: {},
        props: {},
        data() {
            return {
                lang: this.$route.params.lang || 'en'
            }
        },
        computed: {},
        methods: {},
        mounted() {

        },
    }
</script>

<style>

</style>
